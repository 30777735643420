<template>
  <Account title="Organisation activity">
    <AccountHistoryOrgTabs @change="tab = $event" v-bind="{ tab }" />
    <AccountAdminBranchUsage v-if="tab === 'summary'" />
    <AccountHistoryTable depth="org" v-if="tab === 'detail'" />
  </Account>
</template>

<script>
export default {
  name: 'AccountActivityOrg',
  components: {
    Account: () => import('./Account'),
    AccountAdminBranchUsage: () =>
      import('../components/admin/AccountAdminBranchUsage'),
    AccountHistoryTable: () =>
      import('../components/history/AccountHistoryTable'),
    AccountHistoryOrgTabs: () =>
      import('../components/history/AccountHistoryOrgTabs')
  },
  data: () => ({ tab: 'summary' })
}
</script>
